.experience__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__container > div{
    
    padding: 2.4rem 5rem ;
    border-radius: 2rem;
    border: 1px solid transparent;
    background: var(--color-bg-variant);
    transition: var(--transition);
}
.experience__container > div:hover{
    background: transparent;
    background: var(--color-bg-varient);
     
    
}


.experience__container > div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.experience__details{
    display: flex;
    gap: 1rem;
}

.experience__details-icons{
    margin-top: 25px;
    color: var(--color-primary);
}



@media screen and (max-width: 1024px) {
.experience__container{
    grid-template-columns: 1fr;

}

.experience__container > div{
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
}

.experience__content{
    padding: 1rem;
}
    }
    
    @media screen and (max-width: 600px) {
.experience__container{
    gap: 1rem;
}

.experience__container > div{
    width: 90%;
    padding: 2rem;
    margin-right: 12px;

}
    
    }

    
    @media screen and (max-width: 375px) {

        .experience_section{
            margin-top: 120vh;
            /* margin-left: 80px; */
            
            
            }
    }
    @media screen and (max-width: 320px) {
    
           .experience_section{
            
            margin-top: 290vh;
            /* margin-left: 80px; */
            
            }
    }
    @media screen and (max-width: 425px) {
    
               .experience_section{
            margin-top: 160vh;
            /* margin-left: 20px; */
            
            }
    }