.about__container{
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me{
    width: 140%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg,transparent,var(--color-primary),transparent);
    display: grid;
    place-items: center; 
    /* width: 100%; */
    /* height: 55vh; */
    display: flex;

    /* background-color: coral; */
}



.about__me-image{
    
    border-radius: 2rem;
    overflow: hidden;
    height: 100%;
    width: 100%;
    transform: rotate(10deg);
    transition: var(--transition);

}

.about-image{
    background-color: chocolate ;
    width: 100%;
    height: 55vh;
}

.about__me-image:hover{
    transform: rotate(0);
}


.about__cards{
    display: grid;
    grid-template-columns: repeat(3,1fr);
gap: 1.5rem;

}

.about__card{
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    text-align: center;
    transition: var(--transition);
}


.about__card:hover{
    background: transparent;
    border-color: var(--color-bg-variant);
}

.about__icon{
    color: var(--color-primary);
    font-size: 1.4rem;
    /* margin-bottom: 1rem */
    margin-top: 1rem;
}


.about__card h5{
    font-size: 0.95rem;
}

.about__card small{
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p{
margin: 2rem 0 2.6rem;
color: var(--color-light);

}
.myimage{
    background:linear-gradient(45deg,transparent,var(--color-primary),transparent);
    width:110%;
    height:70%;
    border-radius:2rem;
}


@media screen and (max-width: 1024px) {
    .about__container{
        grid-template-columns: 1fr;
        gap: 0;

    }

   
        .myimage{
          
            width: 60%;
            height: 94%;
            margin: -1rem auto -8rem;
        }

    

    .about__content p{
margin: 1rem 0 1.5rem;
    }

    }
    
    @media screen and (max-width: 600px) {
.about__me{
    width: 65%;
    margin: 0 auto 3rem;

}

.about__cards{
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}
    
.about__content{
    text-align: center;
}

.about__content p{
    margin: 1.5rem 0;
}
    
    }