.container.contact__container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}



.contact_options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 150%;
   
}

.contact_option{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);

}


.contact_option:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact_option-icons{
 font-size: 1.5rem;
 margin-bottom: 0.5rem;
}

.contact_options a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;

}


form{
    margin-left: 10vh;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

}

input,textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);

}


@media screen and (max-width: 1024px) {
    .contact_section{
        margin-top: 90vh;
    }
    .container.contact__container{
        
        gap: 2rem;
    margin-left: 10vh;
    }

    form{
        margin-right: 0vh;
    }
   
   
   
        }
        
        @media screen and (max-width: 600px) {
            .container.contact__container{
                grid-template-columns: 1fr; 
               width: var(--container-width-sm);
            
            }
            .contact_option{
                background: var(--color-bg-variant);
                width: 50%;
                border-radius: 1.2rem;
                text-align: center;
                border: 1px solid transparent;
                transition: var(--transition);
            
            }
            
    
   
        
        }

        @media screen and (max-width: 375px) {

            .contact_section{
                margin-top: 120vh;
                /* margin-left: 80px; */
                
                
                }
        }
        @media screen and (max-width: 320px) {
        
               .contact_section{
                
                margin-top: 290vh;
                /* margin-left: 80px; */
                
                }
        }
        @media screen and (max-width: 425px) {
        
                   .contact_section{
                margin-top: 160vh;
                /* margin-left: 20px; */
                
                }
                form{
                    margin-right: 20vh;
                }
        }
    
        
        